/* Season */

#revenue-chart {
    margin-top: 4em;
}

.tooltip {
    width: auto !important;
}

#teams-chart>div,
.legend {
    display: table;
    margin: auto !important;
    stroke: transparent !important;
}

.season-square {
    margin-top: 1em;
    width: 240px;
    /* With this with it fits exactly 5 items on 1280 res */
    min-height: 14em;
    background-color: white;
    position: relative;
    margin-left: 20px;
    border-radius: 1px;
}

.season-square a {
    text-decoration: none;
}

.season-square strong {
    margin-left: 0.5em;
    margin-right: 0.5em;
}

.season-square .participants {
    background-color: white;
}

.season-square .teams {
    background-color: white;
}

.season-actions {
    padding: 5px 5px 0;
    position: fixed;
    right: 0;
    z-index: 1;
}

.season-actions>button {
    margin-left: 5px;
}

.season-name {
    height: 6em;
    padding-left: 1em;
    padding-right: 1em;
    color: black;
}

.season-banner-wrapper {
    height: auto;
    padding: 5px;
}

.season-banner {
    height: 2em;
    width: 100%;
    background-size: cover;
}

.season-banner.camp {
    background: url('/images/banners/banner_camp.jpg') no-repeat center center;
}

.season-banner.competitive {
    background: url('/images/banners/banner_competitive.jpg') no-repeat center center;
}

.season-banner.recreational {
    background: url('/images/banners/banner_recreational.jpg') no-repeat center center;
}

.season-banner.tournament {
    background: url('/images/banners/banner_tournament.jpg') no-repeat center center;
}

.season-list {
    padding-top: 5em;
}

.season-logo {
    height: 4.5em;
    margin: auto;
    width: auto;
    text-align: center;
    display: block;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    background-size: contain;
}

.season-comunity-name {
    height: 2em !important;
    position: absolute;
    bottom: 0;
    left: 0;
    padding-left: 1em;
    padding-right: 1em;
    right: 2em;
    border-top: 1px solid #e5e5e5;
    text-transform: uppercase;
}

.season-vertical-divider {
    border-left: 1px solid #e5e5e5 !important;
}

.season-comunity-edit-button {
    height: 2em;
    border-left: 1px solid #e5e5e5;
    border-top: 1px solid #e5e5e5 !important;
    color: white !important;
    padding: 0 !important;
    line-height: 2em;
    background-color: #808080;
}

.season-comunity-edit-button:first-child {
    border-left: 0px !important;
}

.season-comunity-edit-button:hover {
    background-color: #aaa9a9;
}

.season-gray-stripe {
    padding: 1.5em 0;
    background-color: #e5e5e5;
    height: 2em;
    border-bottom: 2px solid white;
}

.season-teams-and-parts-wrapper {
    min-height: 4em;
}

.season-gray-striper>strong {
    margin-left: 1em;
    margin-right: 1em;
}

#season .title {
    font-size: 14pt;
    padding: 0.5em 0;
    background-color: black;
}
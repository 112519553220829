/*#calendar {
    -ms-transform: scale(0.5, 0.5);
    -webkit-transform: scale(0.5, 0.5);
    transform: scale(0.5, 0.5);
}*/
.rangeslider {
    margin: 8px 0 !important;
}
#full-calendar .filters-wrapper {
    z-index: 1;
}

.fc-resource-cell {
    font-size: 10pt;
    padding: 0.5em 1em !important;
}

.fc-view-container {
    background-color: white;
}

.fc-toolbar {
    display: none;
}

.fc-toolbar h2 {
    color: white;
}

.fc-event {
    padding: 4px 0.5em 0 0.5em;
    opacity: 1;
}

.fc-event.inactive {
    opacity: 0.6;
}

.fc-event.active {
    opacity: 1;
}

/*.fc-scroller {
    height: auto !important;
}*/

#calendar-filters {
    background-color: white;
    color: black;
    border-left: 1px solid black;
    border-right: 1px solid black;
    transition: all 0.3s ease;
    position: absolute;
    top: 3em;
    bottom: 0;
    left: 0;
    width: 270px;
    overflow-y: auto;
}

#calendar-wrapper {
    position: absolute;   
    left: 270px;
    right: 0;
    transition: all 0.3s ease;
    overflow: auto;
}

#calendar-filters .nav-tabs {
    background-color: #e5e5e5;
}

#calendar-filters.on {
    right: 0px;
}

#calendar-filters-toggler {
    position: fixed;
    top: 9em;
    right: 20px;
    z-index: 1;
    transition: all 0.3s ease;
}

#calendar-filters-toggler.on {
    right: 410px;
}
.fc-event {
    padding: 0 !important;
    overflow: hidden;
}
.fc {
    font-size: 0.8em !important;
}

body > .tooltip {
    opacity: 1
}

.popper,
.tooltip {
  position: absolute;
  z-index: 9999;
  background: #000;
  color: black;
  width: 150px;
  border-radius: 3px;
  box-shadow: 0 0 2px rgba(0,0,0,0.5);
  padding: 10px;
  text-align: center;
}
.style5 .tooltip {
  background: #1E252B;
  color: #FFFFFF;
  max-width: 200px;
  width: auto;
  font-size: .8rem;
  padding: .5em 1em;
}
.popper .popper__arrow,
.tooltip .tooltip-arrow {
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  margin: 5px;
}

.tooltip .tooltip-arrow,
.popper .popper__arrow {
  border-color: #000;
}
.style5 .tooltip .tooltip-arrow {
  border-color: #1E252B;
}
.popper[x-placement^="top"],
.tooltip[x-placement^="top"] {
  margin-bottom: 5px;
}
.popper[x-placement^="top"] .popper__arrow,
.tooltip[x-placement^="top"] .tooltip-arrow {
  border-width: 5px 5px 0 5px;
  border-left-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  bottom: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}
.popper[x-placement^="bottom"],
.tooltip[x-placement^="bottom"] {
  margin-top: 5px;
}
.tooltip[x-placement^="bottom"] .tooltip-arrow,
.popper[x-placement^="bottom"] .popper__arrow {
  border-width: 0 5px 5px 5px;
  border-left-color: transparent;
  border-right-color: transparent;
  border-top-color: transparent;
  top: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}
.tooltip[x-placement^="right"],
.popper[x-placement^="right"] {
  margin-left: 5px;
}
.popper[x-placement^="right"] .popper__arrow,
.tooltip[x-placement^="right"] .tooltip-arrow {
  border-width: 5px 5px 5px 0;
  border-left-color: transparent;
  border-top-color: transparent;
  border-bottom-color: transparent;
  left: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}
.popper[x-placement^="left"],
.tooltip[x-placement^="left"] {
  margin-right: 5px;
}
.popper[x-placement^="left"] .popper__arrow,
.tooltip[x-placement^="left"] .tooltip-arrow {
  border-width: 5px 0 5px 5px;
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  right: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}
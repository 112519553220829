#flight-manager {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0
}

#flight-manager>.divisions-container {
    position: absolute;
    top: 5px;
    left: 5px;
    bottom: 5px;
    width: 300px;
    background-color: white;
}

#flight-manager>.filters-wrapper {
    position: absolute;
    top: 5px;
    left: 310px;
    right: 5px;
    height: 50px;
}

#flight-manager>.flights-wrapper {
    position: absolute;
    top: 65px;
    bottom: 5px;
    left: 310px;
    right: 5px;
    overflow-x: auto;
}

#registration-fees .flight {
    position: absolute;
    top: 10px;
    bottom: 0;
    margin-left: 10px;
    width: 250px;
    background-color: white;
    overflow: hidden;
}

#registration-fees .flight.isOver {
    border-right: 4em solid #002549;
}

.drop-target {
    border-top: 20px solid #e5e5e5 !important;
}

.droppable-area {
    stroke: 1px solid blue;
}

#flight-manager .divisions-list {
    position: absolute;
    top: 2em;
    bottom: 0;
    width: 100%;
    overflow-y: auto;
}

#flight-manager .Select {
    min-width: 14em;
    z-index: 2;
}
#registration-fees .division-list-item,
#flight-manager .division-list-item {
    padding: 0.2em;
}

#registration-fees#registration-fees#registration-fees .division-list-item>* {
    margin-right: 0.5em;
}

#registration-fees#registration-fees#registration-fees .division-list-item>i {
    width: 1em;
    height: 1em;
}

#registration-fees#registration-fees#registration-fees .division-list-teams-wrapper {
    padding-left: 1em;
}

.division-list-teams-wrapper {
    overflow: hidden;
    width: 100%;
    /*max-height: 1000px;*/
    transition: max-height 0.25s ease-in;
}

#registration-fees#registration-fees .off>.division-list-teams-wrapper {
    max-height: 0;
    transition: max-height 0.15s ease-out;
    margin-bottom: 0;
}
#registration-fees .flight-list-teams-wrapper {
    position: absolute;
    top: 4.3em;
    bottom: 0;
    width: 100%;
    overflow-y: auto;
}

#registration-fees .flight-list-teams-wrapper>* {
    padding-left: 1em;
    z-index: 1;
}

.flight button[type="submit"] {
    height: 100%;
}



#registration-fees .flight-list-teams-wrapper>.watermark {
    position: absolute;
    bottom: 1em;
    width: 100%;
    text-align: center;
    padding: 0;
    margin: 0;
    font-size: 60pt;
    color: #efefef;
    z-index: 0;
}
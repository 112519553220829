#build-popup {
    /*max-height: 400px;*/
    /*overflow: auto;*/
}

#build-popup .divisions-wrapper {
    overflow-y: auto;
    list-style: none;
}

#build-popup input[type=checkbox] {
    margin-left: 0.5em;
}


#build-popup .divisions-wrapper .division,
#build-popup .divisions-wrapper .flight {
    min-height: 2em;
    background-color: white;
    cursor: pointer;
}

#build-popup .divisions-wrapper .division>span,
#build-popup .divisions-wrapper .flight>span {
    height: 2em;
    display: block;
    line-height: 2em;
}

#build-popup .divisions-wrapper .division>span:hover,
#build-popup .divisions-wrapper .flight>span:hover {
    background-color: #e5e5e5;
}

#build-popup ul {
    list-style: none;
}

#build-popup li>ul {
    padding-left: 2em;
}

#build-popup .divisions-wrapper section.icons-wrapper {
    display: inline;
}

#build-popup .divisions-wrapper section.icons-wrapper>i {
    margin-left: 0.5em;
}

#build-popup .divisions-wrapper i.fa {
    margin-right: 0.5em;
    width: 0.7em;
}

#build-popup .flights-wrapper {
    overflow: hidden;
    width: 100%;
    max-height: 1001px;
    transition: max-height 0.25s ease-in;
}

#build-popup .division.off>.flights-wrapper {
    max-height: 0;
    transition: max-height 0.15s ease-out;
}
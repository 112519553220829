.exceptions-rule-list {
    list-style: none;
    margin: 0;
    padding: 0 0 0 0;
    overflow-y: auto;
    position: absolute;
    top: 3em;
    width: 100%;
    bottom: 0;
}

.exceptions-rule-list>li.exception-rule {
    float: left;
    margin-right: 1em;
    margin-bottom: 0.5em;
    margin-top: 0.5em;
}

li.exception-rule {
    position: relative;
    width: 298px;
    height: 190px;    
    border: 1px solid black;
}

.exception-rule .movable-hint {
    position: absolute;
    bottom: 5px;
    left: 5px;
    cursor: -webkit-grab;
    opacity: 0;
}

.exception-rule:hover .movable-hint {
    opacity: 1;
}

.exception-rule .header {
    border-bottom: 1px solid #e5e5e5;
}

.exception-rule>.flex-column {
    min-height: 140px;
}

.exception-rule.dragging {
    -ms-transform: rotate(1deg);
    /* IE 9 */
    -webkit-transform: rotate(1deg);
    /* Chrome, Safari, Opera */
    transform: rotate(1deg);
    box-shadow: 5px 5px 5px #888888;
    overflow: hidden;
}
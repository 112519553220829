#schedule-master-view {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0
}

#schedule-master-view>.filters-wrapper {
    position: absolute;
    top: 5px;
    left: 20px;
    right: 5px;
    height: 40px;
}

#schedule-master-view>.work-area {
    position: absolute;
    top: 60px;
    left: 5px;
    bottom: 5px;
    right: 5px;
}

#schedule-master-view ul {
    clear: both;
    margin: 0;
    padding: 0
}

#schedule-master-view ul.list>li {
    list-style: none;
    display: block;
    border-bottom: 1px solid #e5e5e5;
    padding: 1em;
}

#schedule-master-view ul.tiles>li {
    list-style: none;
    float: left;
    border: 1px solid #e5e5e5;
    width: 200px;
    height: 300px;
    margin: 0 1em 1em 0;
}

.no-games-found {
    padding: 1em;
}
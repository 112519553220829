#tournaments .actions-wrapper {
    position: absolute;
    top: 0;
    left: 2.5em;
    right: 0;
    height: 3em;
}

#tournaments .filters-wrapper {
    position: absolute;
    top: 4em;
    bottom: 0;
    right: 0;
    width: 300px;
    overflow-y: auto;
    background-color: #e5e5e5;
}

#tournaments .brackets-wrapper {
    position: absolute;
    top: 4em;
    bottom: 0;
    left: 0;
    right: 300px;
    overflow: auto;
    border-top: 1px solid #84878c;
    border-right: 1px solid #84878c;
}

#tournaments .bracket-container {
    width: 100%;
    /*zoom: 0.5;
    -moz-transform: scale(0.5);*/
}

#tournaments .tournament-bracket {
    width: 100%;
    padding-right: 20px;
    position: relative;
    /*overflow-x: auto;*/
}

#tournaments .tournament-bracket:last-child {
    border-bottom: 1px solid #84878c;
}

#tournaments .tournament-bracket:first-child {
    border-top: 1px solid #84878c;
}

#tournaments .bracket-group {
    width: 250px;
    height: 100%;
    position: relative;
}

#tournaments .bracket-game-wrapper {
    width: 250px;
    min-height: 100px;
    height: 100%;
    position: relative;
}

#tournaments .bracket-game {
    width: 200px;
    height: 100%;
    border-bottom: 1px solid #84878c;
    /*background-color: gray;*/
}

#tournaments .bracket-gray-column {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 200px;
    background-color: #84878c;
}

#tournaments .round-number {
    width: 200px; 
    text-align: center;
    margin-left: 50px;
    height: auto;
    position: absolute;
    top: 0; 
    font-size: 10px;   
}

#tournaments .add-bracket-node-button {
    width: 20px; 
    height: 20px;
    border-radius: 10px;
    line-height: 16px;
    font-size: 16px !important;
    
}

#tournaments .connections-wrapper {
    width: 50px;
    height: 100%;
}

#tournaments .connections-wrapper .left {
    height: 100%;
    width: 25px;
    position: relative;
}

#tournaments .connections-wrapper .right {
    height: 100%;
    width: 25px;
    position: relative;
}

#tournaments .connector-box-top {
    width: 25px;
    height: 50%;
}

#tournaments .connector-box-bottom {
    width: 25px;
    height: 50%;
}

#tournaments .connector-box-center {
    width: 5px;
    height: 1px; 
    background-color: white;
}

#tournaments .left .connector {
    
    /* Firefox */
height: -moz-calc(50% - 26px);
/* WebKit */
height: -webkit-calc(50% - 26px);
/* Opera */
height: -o-calc(50% - 26px);
/* Standard */
height: calc(50% - 26px);
    
}

#tournaments .left .connector-box-bottom>.connector {    
    margin-top: 26px;
}
#tournaments .left .connector-box-bottom>.connector>.L {
    border-right: 1px solid white;   
    border-bottom: 1px solid white;
}
#tournaments .left .connector-box-bottom>.connector>.Floor {    
    border-top: 1px solid white;
}

#tournaments .left .connector-box-top>.connector {    
    margin-bottom: 26px;
}
#tournaments .left .connector-box-top>.connector>.L {
    border-right: 1px solid white;   
    border-top: 1px solid white;
}
#tournaments .left .connector-box-top>.connector>.Floor {    
    border-bottom: 1px solid white;
}

#tournaments .connections-wrapper .right>.gap {
    height: 2em;
    width: 25px;
    
}

#tournaments .placement-item {
    padding: 0.2em 0.5em;
    border-bottom: 1px solid #e5e5e5;
}

#tournaments .placement-item .fa {
    opacity: 0;
    color: #c0c0c0;
    cursor: move;
}

#tournaments .placement-item:hover .fa {
    opacity: 1;
}


/* Game section  */

#tournaments .bracket-game .game {
    width: 100%;
    min-height: 170px;
    background-color: #e5e5e5;
    border-left: 2px solid #17a2b8;
    border-right: 2px solid #17a2b8;
    border-bottom: 2px solid #17a2b8;
    border-radius: 2px;
    box-shadow: 2px 2px #303030;
}

#tournaments .bracket-game .game .game-name {
    background-color: #17a2b8;
    color: white;
}

#tournaments .game .team-droppable-area {
    height: 40px;
    background-color: #e0e0e0;
}

#tournaments .game .location-and-date {
    height: 25px;
}
nav {
    border-bottom: 5px solid white;
    height: 2em;
    width: 100%;
    background-color: #a9a9a9;
}

.nav-home-button {
    width: 12em;
    margin: 0;
    padding: 0;
    border-width: 0;
    border-right: 5px solid white;
    border-left: 5px solid white;
    height: 100%;
    background-color: transparent;
}

.nav-back-button {
    border-right: 1px solid white;
    color: white;
}

.nav-back-button:hover {
    border-right: 1px solid white;
    color: #e5e5e5;
}
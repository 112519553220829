.fields-list {
    padding-top: 3em;
}

.location-square {
    margin-top: 1em;
    width: 240px;
    min-height: 14em;
    background-color: white;
    position: relative;
    margin-left: 20px;
    border-radius: 2px;
    position: relative;
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
    border: 1px solid #e5e5e5;
}

.location-square.hoverable:hover {
    visibility: visible;
    opacity: 1;
    -webkit-transform: scale(1.05);
    -moz-transform: scale(1.05);
    -ms-transform: scale(1.05);
    -o-transform: scale(1.05);
    transform: scale(1.05);
}

.location-square .bg-info {
    padding: 0 1em;
    position: absolute;
    top: 5px;
    bottom: 4em;
    left: 5px;
    right: 5px;
}

.location-name {
    height: 5em;
    padding: 0 1em;
    line-height: 1em;
}

.location-square .field {
    border: 1px solid #17a2b8;
    padding-left: 0.5em;
}

.location-square .close-expand-button {
    position: absolute;
    top: 0;
    right: 0;
}

.location-square .option-buttons {
    position: absolute;
    bottom: 0;
}

.location-square.soccer {
    background: url('/images/community_tiles/1.png') no-repeat center center;
    background-size: cover;
}

.location-square.football {
    background: url('/images/community_tiles/2.png') no-repeat center center;
    background-size: cover;
}

.location-square.baseball {
    background: url('/images/community_tiles/3.png') no-repeat center center;
    background-size: cover;
}

.location-square.softball {
    background: url('/images/community_tiles/4.png') no-repeat center center;
    background-size: cover;
}

.location-square.hockey {
    background: url('/images/community_tiles/5.png') no-repeat center center;
    background-size: cover;
}

.location-square.lacrosse {
    background: url('/images/community_tiles/6.png') no-repeat center center;
    background-size: cover;
}

.location-square.basketball {
    background: url('/images/community_tiles/7.png') no-repeat center center;
    background-size: cover;
}

.location-square.volleyball {
    background: url('/images/community_tiles/8.png') no-repeat center center;
    background-size: cover;
}

.location-square.swimming {
    background: url('/images/community_tiles/9.png') no-repeat center center;
    background-size: cover;
}

.location-square.bowling {
    background: url('/images/community_tiles/10.png') no-repeat center center;
    background-size: cover;
}

.location-square.motorsports {
    background: url('/images/community_tiles/11.png') no-repeat center center;
    background-size: cover;
}

.active-field {
    position: absolute;
    top: 0;
    height: 100% !important;
    left: 0;
    z-index: 9;
    width: 100% !important;
    margin: 0 !important;
}

.active-field .location-name {
    height: 4em;
}
.fade-enter {
    opacity: 0;
    z-index: 1;
}

.fade-enter.fade-enter-active {
    opacity: 1;    
    transition: opacity 250ms ease-in;
}

.something {
    animation-name: example;
    animation-duration: 1s;
}

@keyframes example {
    from {
        left: 120px;
    }
    to {
        left: 0
    }
}

.example-enter {
    opacity: 0.01;
}

.example-enter.example-enter-active {
    opacity: 1;
    transition: opacity 500ms ease-in;
}

.example-leave {
    opacity: 1;
}

.example-leave.example-leave-active {
    opacity: 0.01;
    transition: opacity 300ms ease-in;
}


/* Shake */

.shake {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
}

@keyframes shake {
    10%,
    90% {
        transform: translate3d(-1px, 0, 0);
    }
    20%,
    80% {
        transform: translate3d(2px, 0, 0);
    }
    30%,
    50%,
    70% {
        transform: translate3d(-4px, 0, 0);
    }
    40%,
    60% {
        transform: translate3d(4px, 0, 0);
    }
}

@keyframes loadingA {
    0% {
        height: 15px;
    }
    50% {
        height: 35px;
    }
    100% {
        height: 15px;
    }
}

@keyframes loadingB {
    0% {
        width: 15px;
    }
    50% {
        width: 35px;
    }
    100% {
        width: 15px;
    }
}

@keyframes loadingC {
    0% {
        transform: translate(0, 0);
    }
    50% {
        transform: translate(0, 15px);
    }
    100% {
        transform: translate(0, 0);
    }
}

@keyframes loadingD {
    0% {
        transform: rotate(0deg);
    }
    50% {
        transform: rotate(180deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes loadingE {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes loadingF {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes loadingG {
    0% {
        transform: translate(0, 0) rotate(0deg);
    }
    50% {
        transform: translate(70px, 0) rotate(360deg);
    }
    100% {
        transform: translate(0, 0) rotate(0deg);
    }
}

@keyframes loadingH {
    0% {
        width: 15px;
    }
    50% {
        width: 35px;
        padding: 4px;
    }
    100% {
        width: 15px;
    }
}

@keyframes loadingI {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes bounce {
    0%,
    100% {
        transform: scale(0.0);
    }
    50% {
        transform: scale(1.0);
    }
}

@keyframes loadingJ {
    0%,
    100% {
        transform: translate(0, 0);
    }
    50% {
        transform: translate(10em, 0);
        background-color: #2cad3c;
        width: 25px;
    }
}

.bar {
    width: 25px;
    height: 6px;
    background-color: #4b9cdb;
    animation: loadingJ 2s cubic-bezier(.50, .50, .50, 1) infinite;
}


/* Heart beat animation */

@keyframes beat {
    to {
        transform: scale(1.4);
    }
}

.heart {
    animation: beat 1s infinite alternate;
    transform-origin: center;
}


  
  i.horizontal-shake {
    animation: horizontal-shaking 0.5s;
  } 
  
  @keyframes horizontal-shaking {
    0% { transform: translateX(0) }
    25% { transform: translateX(5px) }
    50% { transform: translateX(-5px) }
    75% { transform: translateX(5px) }
    100% { transform: translateX(0) }
  } 
  
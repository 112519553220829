#schedule-manager .left-side .divisions-wrapper {
    overflow-y: auto;
    list-style: none;
}

#schedule-manager .left-side .divisions-wrapper .division,
#schedule-manager .left-side .divisions-wrapper .flight {
    min-height: 2em;
    background-color: white;
    cursor: pointer;
}

#schedule-manager .left-side .divisions-wrapper .division>span,
#schedule-manager .left-side .divisions-wrapper .flight>span {
    height: 2em;
    display: block;
    line-height: 2em;
}

#schedule-manager .left-side .divisions-wrapper .division>span:hover,
#schedule-manager .left-side .divisions-wrapper .flight>span:hover {
    background-color: #e5e5e5;
}

#schedule-manager li>ul {    
    list-style: none;
}

#schedule-manager .left-side .divisions-wrapper .icons-wrapper {
    display: inline;
}

#schedule-manager .left-side .divisions-wrapper .icons-wrapper>i {
    margin-left: 0.5em;
}

#schedule-manager .flights-wrapper {
    overflow: hidden;
    width: 100%;
    /*max-height: 1000px;*/
    transition: max-height 0.25s ease-in;
}

#schedule-manager .division.off>.flights-wrapper {
    max-height: 0;
    transition: max-height 0.15s ease-out;
}
.green,
.success,
.Active,
.RECEIVED,
.Completed {
    color: #2cad3c !important;
}

.white {
    color: white !important;
}

.white-ish,
.whiteish {
    color: #e5e5e5 !important;
}

.rangeslider__fill { 
    background-color: #108800!important;
}

.jeff_blue {
    color: #013469 !important;
}

.bg_jeff_blue {
    background-color: #002549 !important;
}


.blue-dark,
.Shopping,
.dark-blue {
    color: #042549 !important;
}

.gray {
    color: #999 !important;
}

.black {
    color: black !important;
}

.btn-info {
    color: #fff;
    background-color: #17a2b8 !important;
    border-color: #17a2b8 !important;
}

.blue,
.PENDING,
.Pending {
    color: #008db5 !important;
}

.yellow,
.Waitlisted,
.Waiting {
    color: #fdee21 !important;
}

.orange {
    color: fdee21 !important;
}

.red,
.rejected,
.Inactive,
.Denied,
.REFUND,
.Late {
    color: #dc3545 !important;
}

.input-danger {
    border: 2px solid #dc3545 !important;
}

.transparent {
    color: transparent;
}

.teal {
    color: teal;
}

.purple,
.Tryout {
    color: #a713a2 !important;
}

.bg-purple {
    background-color: #a713a2 !important;
}

.bg-green {
    background-color: #2cad3c !important
}

.bg-yellow {
    background-color: #ffcc00 !important
}

.bg-dark-blue {
    background-color: #042549 !important;
}

.bg-darker-blue {
    background-color: #050525 !important;
}

.bg-white {
    background-color: #fff !important
}

.bg-transparent {
    background-color: transparent !important;
}

.bg-whiteish {
    background-color: #e5e5e5 !important
}


.bg-purple {
    background-color: rgb(125, 8, 92) !important;
    color: white;
}

.bg-black {
    background-color: black !important
}

.bg-gray {
    background-color: #e5e5e5 !important
}

.bg-gray-darkish {
    background-color: #cccccc !important
}

.bg-gray-dark {
    background-color: #525252 !important
}

.bg-Competitive {
    background-color: #be0344;
}

.bg-Hybrid {
    background-color: red;
}

.bg-Camp,
.bg-orange {
    background-color: #fd930c;
}

.bg-Recreational {
    background-color: #259bd9;
}

.bg-Tournament {
    background-color: #4fb03c;
}

.bg-blue-ish {
    background-color: #617790 !important;
}

.bg-blue-dark {
    background-color: #121b32 !important;
}

.color-Competitive {
    color: #be0344;
}

.color-Camp {
    color: #fd930c;
}

.color-Recreational {
    color: #259bd9;
}

.color-Tournament {
    color: #4fb03c !important;
}

.color-Hybrid {
    color: red !important;
}

.inner-shadow {
    -moz-box-shadow: inset 0 0 10px #000000;
    -webkit-box-shadow: inset 0 0 10px #000000;
    box-shadow: inset 0 0 10px #000000;
}

.light-gray {
    color: #c3c3c3
}

.brown {
    color: brown
}

.highlighted-date {
    border-radius: 0.3rem;
    background-color: #4fb03c;
    color: #fff;
}

.premium-gradient {
    background: rgb(100, 169, 212);
    background: linear-gradient(90deg, rgba(100, 169, 212, 1) 0%, rgba(172, 215, 100, 1) 100%);
}

.black-input {
    border-radius: 12px;
    background-color: transparent;
    border: 2px solid #343f4b;
}

.black-input.drop-section {
    border: 2px dashed #343f4b !important;
}

.black-input.error-input {
    border-radius: 12px;
    border: 2px solid var(--color-system-danger-600, #CC0000) !important;
    background: var(--color-system-error-fill-25, #CC0000CC);
    box-shadow: 0px 1px 4px 0px rgba(204, 34, 0, 0.45);
    background-color: var(--color-system-error-fill-25, #CC0000CC) !important;
}

.black-input>* {
    background-color: transparent;
    border-width: 0 !important;
    outline: none !important;
    border-radius: 12px;
}

.black-input>*::placeholder {
    color: black;
    opacity: 0.9;
    /* Firefox */
}

.black-input>*::-ms-input-placeholder {
    /* Edge 12 -18 */
    color: black;
    opacity: 0.9;
}

.modal-dialog {
    border-radius: 12px;    
    border: 2px solid #343f4b;
}

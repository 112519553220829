body {
    margin: 0;
    padding: 0;
    font-family: 'Montserrat', sans-serif;
    background-color: #002549 !important;
}

header {
    width: 100%;
    height: 6em;
    background-color: white;
    clear: both;
}

header .square {
    width: 6em;
    height: 6em;
}

a,
button {
    text-decoration: none !important;
}

.max-lines-1 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    /* number of lines to show */
    line-clamp: 1;
    -webkit-box-orient: vertical;
}

.btn-deafult {
    background-color: white;
}

.droppable {
    border-radius: 5px;
    padding: 10px
}

.droppable.action {
    background-color: #e5e5e5;
    border: 2px dashed #AAA;
}

.drop-area.action {
    background-color: #e5e5e5;
}

#logo {
    background: url('/images/logos/logo_no_ball.png');
    background-repeat: no-repeat;
    background-size: 80%;
    width: 12em;
    height: 6em;
    background-position: center center;
}

#logo-sqwad {
    width: 12em;
    height: 6em;
}

#capture-logo {
    background: url('/images/logo-blue.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
}

#main-content {
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: white;
    transition: all 2s ease 0s;
    top: 6em;
}


#main-content-6 {
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: white;
    transition: all 2s ease 0s;
    top: 6em;
}

#main-content.hideSubNav {
    top: 6em;
}

#content-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #e5e5e5;
    background-size: cover;
    overflow-y: auto;
    overflow-x: hidden;
    transition: all 0.25s ease-in;
}

#content-wrapper.with-menu {
    left: 12em;
}

#content-wrapper-menuless {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
    overflow-y: auto;
    transition: all 0.25s ease-in;
}

#content-wrapper.hidden,
#hide-menu-action.hidden {
    left: 0px;
}

@media (max-width: 870px) {
    #content-wrapper.with-menu {
        left: 0;
    }
}

#hide-menu-action {
    position: fixed;
    top: 4em;    
    left: 0em;    
    transition: all 0.25s ease-in;
    /*border-top: 1px solid black;
    border-bottom: 1px solid black;
    border-right: 1px solid black;*/
}



#right-menu {
    right: -300px;
    transition: all 0.25s ease-in;
}

#right-menu.shown {
    right: 0;
}

#welcome,
.centered-message {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    text-transform: uppercase;
}

.centered-message-normal {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

}

.frame {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.wrapper {
    padding: 5px !important;
    position: relative;
}

.padded-on-sides {
    padding-left: 1em !important;
    padding-right: 1em !important;
}

.Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
}

.fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.popover {
    max-width: inherit !important;
}

.dark-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
}

.green-header {
    background-color: #2cad3c;
    display: block;
}

.blue-header {
    background-color: #002549;
    display: block;
}

.gray-header {
    background-color: gray;
    display: block;
}

.Select-menu-outer {
    z-index: 1 !important;
}

.float-center {
    float: right;
    position: relative;
    left: -50%;
    /* or right 50% */
    text-align: left;
}

.float-center>.child {
    position: relative;
    left: 50%;
    overflow-y: hidden;
}

.day-button {
    margin-right: 5px;
    width: 35px;
    border-radius: 3px !important;
    border: 1px solid black !important;
}

.right-actions {
    margin: 10px 0 0 0;
    position: fixed;
    right: 1em;
    z-index: 1;
}

.left-actions {
    margin: 10px 0 0 10px;
    position: absolute;
    left: 2em;
    z-index: 1;
}

.top-action-area {
    position: absolute;
    top: 0em;
    height: 3em;
    left: 0;
    right: 0
}

.bottom-action-area {
    position: absolute;
    top: 3em;
    bottom: 0;
    left: 0;
    right: 0
}

.right-actions>* {
    margin-left: 0.5em;
}

.left-actions>* {
    margin-right: 0.5em;
}

.modal-title {
    width: 100% !important;
}


/* Bootstrap modal tweeks */

@media (min-width: 576px) {
    .modal-dialog {
        max-width: 926px !important;
    }
}

.modal-body {
    padding: 0 !important;
}

.modal-body .divider-vertical {
    border-left: 1px solid #e9ecef;
}

.modal-header.header-1 {
    background-color: rgb(37, 155, 217) !important;
    color: white !important;
}

.modal-header.header-2 {
    background-color: rgb(106, 14, 51) !important;
    color: white !important;
}

.modal-header.header-3 {
    background-color: rgb(79, 176, 60) !important;
    color: white !important;
}

.modal-header.header-4 {
    background-color: rgb(253, 147, 12) !important;
    color: white !important;
}

.modal-header.white .close {
    color: white !important;
}


/* React Datepicker */

.react-datepicker-wrapper input {
    max-width: 100px;
}


/* React Timepicker */

.time_picker_preview {
    height: 20px !important;
}

.time_picker_preview .preview_container {
    width: 100% !important;
    padding: 0 !important;
    text-align: center;
}

.custom-time-scroller {
    min-width: 100px;
}

.overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1040;
    background-color: #000;
    opacity: 0;
    -webkit-transition: opacity 0.15s linear;
    -o-transition: opacity 0.15s linear;
    transition: opacity 0.15s linear
}

.overlay-full {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1040;
    background-color: #000;
    opacity: 0;
    -webkit-transition: opacity 0.15s linear;
    -o-transition: opacity 0.15s linear;
    transition: opacity 0.15s linear
}


.overlay.show,
.overlay-full.show {
    opacity: 0.5;
}

.scrollable {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    overflow-y: auto;
}

.nav-tabs,
.under-ish {
    background-color: #e5e5e5;
}

table.centered td,
table.centered th {
    vertical-align: initial !important;
}

.fc-event .fc-bg {
    background: none !important;
    border: 1px solid gray !important;
}

.no-border-radius {
    border-radius: 0 !important;
}


/* User Modules */

#user-modules .tile,
#registration-player-selection .tile {
    width: 200px;
    height: 300px;
    /*border: 1px solid #e5e5e5;*/
    border-radius: 2px;
    margin-right: 1em;
    margin-left: 1em;
    margin-bottom: 1em;
    transition: all 0.4s;
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
}

.recruitment-tile {
    transition: all 0.4s;
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
}

#user-modules .tile:hover,
#registration-player-selection .tile:hover,
.recruitment-tile:hover {
    visibility: visible;
    opacity: 1;
    -webkit-transform: scale(1.05);
    -moz-transform: scale(1.05);
    -ms-transform: scale(1.05);
    -o-transform: scale(1.05);
    transform: scale(1.05);
}

.right-menu,
.left-menu {
    width: 500px;
}



@media (max-width: 870px) {

    #user-modules .tile {
        width: 100%;
        height: 100px;
        margin-left: 1em;
    }

    .right-menu {
        width: auto;
        left: 100px;
    }

    .left-menu {
        width: auto;
        right: 100px;
    }

    #user-modules .tile:hover,
    #registration-player-selection .tile:hover,
    .recruitment-tile:hover {
        visibility: visible;
        opacity: 1;
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1);
    }
}

.user-avatar-rounded {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    border: 2px solid gray;
    background-color: black;
}

.circular {
    border-radius: 50% !important;
}

.rounded-item {
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    overflow: hidden;
    margin-bottom: 1em;
}

.hoverable {
    -webkit-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    transition: all 0.4s ease;
}

.hoverable:hover {
    visibility: visible;
    opacity: 1;
    -webkit-transform: scale(1.05);
    -moz-transform: scale(1.05);
    -ms-transform: scale(1.05);
    -o-transform: scale(1.05);
    transform: scale(1.05);
}

.sheet {
    width: 920px;
    background: #fff;
    border-radius: 15px;
    /* overflow: hidden; */
    padding: 2em;
    /* margin: 1em 0; */
}

.sheet.letter {
    width: 920px;
    border-radius: 0 !important;
    padding: 1em;
    border-color: 'gray'
}

.sheet.postcard {
    width: 765px;
    border-radius: 0 !important;
    padding: 1em;
    border-color: 'gray'
}

.smallPic {
    width: 40px;
    height: 40px;
    border: 1px solid gray;
    border-radius: 20px;
}

.tile-rainbow {
    background: url('/images/tile-rainbow.png') no-repeat center center;
    background-size: cover;
}

.tile.shadow-box,
.shadow-box {
    box-shadow: 0 2px 2px 0 rgba(157, 157, 157, 0.5);
}

.cover {
    background-size: cover !important;
}

.contain {
    background-size: contain !important;
}

.canvas {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.metric {
    border-radius: 10px;
    background-color: #575757;
}

.hidden-content>* {
    opacity: 0;
}

.table th,
.table td {
    white-space: nowrap;
}

.square-box {
    width: 50px;
    text-align: center;
    height: 30px;
    padding: 0 2px;
    font-size: 20px;
    border: 1px solid #e3e3e3;
    color: black !important;
}

.table th,
.table td {
    vertical-align: middle !important;
}

.ease {
    transition: all 0.3s ease;
}

.viewport {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
}

@media print {
    body * {
        visibility: hidden;
    }

    #printarea,
    #printarea * {
        visibility: visible;
    }

    #printarea {
        position: absolute;
        left: 0;
        top: 20px;
        border: 2px dashed black;
    }
}
.btn-on {
    background-color: #4469C7 !important;
    color: white !important;
    transition: all 0.15s ease-in-out;
}

.btn-on:hover {
    background-color: #28a745 !important;
}

.link-off {
    color: white;
    transition: all 0.15s ease-in-out;
    display: inline-block;
    font-weight: 400;    
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    user-select: none;
    background-color: transparent;    
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.link-on {
    color: #28a745 !important;
    transition: all 0.15s ease-in-out;
}

.btn-off {
    background-color: #002549;
    color: #008db5;
    transition: all 0.15s ease-in-out;
}

.btn-off:hover {
    background-color: #123960;
}

.btn-white {
    background-color: #e5e5e5;
}

button {
    cursor: pointer;
}

.btn-link {
    color: inherit !important;
}

.sub-menu-wrapper>.btn:hover {
    background-color: #ccc;
}
.field-availability-rule-list {
    list-style: none;
    margin: 0;
    padding: 0 0 0 1em;
    overflow-y: auto;
    position: absolute;
    top: 3em;
    width: 100%;
    bottom: 0;
}

.field-availability-rule-list>li.field-availability-rule {
    float: left;
    margin-right: 1em;
    margin-bottom: 0.5em;
    margin-top: 0.5em;
}

li.field-availability-rule {
    position: relative;
    width: 350px;
    min-height: 150px;    
    border: 1px solid black;
}

.field-availability-rule .movable-hint {
    position: absolute;
    bottom: 5px;
    left: 5px;
    cursor: -webkit-grab;
    opacity: 0;
}

.field-availability-rule .day-button {
    margin-right: 0.20em;
    margin-left: 0.20em;
    width: 35px;
    border-radius: 3px !important;
}

.field-availability-rule .names-wrapper {
    padding-left: 0.5em;
}

.field-availability-rule:hover .movable-hint {
    opacity: 1;
}

.field-availability-rule .header {
    border-bottom: 1px solid #e5e5e5;
}

.field-availability-rule.dragging {
    -ms-transform: rotate(1deg);
    /* IE 9 */
    -webkit-transform: rotate(1deg);
    /* Chrome, Safari, Opera */
    transform: rotate(1deg);
    box-shadow: 5px 5px 5px #888888;
    overflow: hidden;
}

.field-availability-rule .time-interval-graph {
    height: auto;
    width: 100%;
    position: relative;
    margin-bottom: 1.5em;
}

.field-availability-rule .time-interval-graph .x-axis {
    border-bottom: 2px dotted gray;
}

.field-availability-rule .time-interval-graph .intervals-wrapper {
    position: relative;
    height: 2em;
    width: 100%;
}

.field-availability-rule .time-interval-graph .intervals-wrapper .interval {
    position: absolute;
    height: 2em;
    top: 0em;
}

.field-availability-rule .time-interval-graph .intervals-wrapper .interval .line {
    position: absolute;
    height: 1em;
    top: 0.5em;
    left: 0;
    right: 0;
    background-color: #2b78e4;
    border: 2px solid black;
}

.field-availability-rule .time-interval-graph .intervals-wrapper .interval .from {
    position: absolute;
    top: 0;
    left: 0;
    border-left: 2px solid black;
    border-top: 2px solid black;
    background-color: #2b78e4;
    padding: 0 2px;
}

.field-availability-rule .time-interval-graph .intervals-wrapper .interval .to {
    position: absolute;
    bottom: 0;
    right: 0;
    border-bottom: 2px solid black;
    border-right: 2px solid black;
    background-color: #2b78e4;
    padding: 0 2px;
}
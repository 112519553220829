
#signature-pad {
    width: 100% !important;
}

.m-signature-pad {
    margin: 0;
    width: 100% !important;
    background-color: #FFFFFF;
}

.m-signature-pad--body canvas {
    background: url('/images/sign.png') no-repeat center center;
    width: 100% !important;
    height: 250px;
}
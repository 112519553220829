.login-wrapper {
    width: 100%;
    height: 100%;
}

.login-form-wrapper {
    width: 300px;
    height: 150px;    
}

#content-wrapper.login {
    left: 0;
}


/*//////////////////////////////////////////////////////////////////
  [ RESTYLE TAG ]*/


/*---------------------------------------------*/

a {
    transition: all 0.4s;
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
}

a:focus {
    outline: none !important;
}


/*//////////////////////////////////////////////////////////////////
  [ utility ]*/


/*==================================================================
  [ Text ]*/

.txt1 {
    font-size: 13px;
    line-height: 1.4;
    color: #999999;
}

.txt2 {
    font-size: 13px;
    line-height: 1.4;
    color: #00ad5f;
}

.txt3 {
    font-size: 15px;
    line-height: 1.4;
    color: #00ad5f;
    text-transform: uppercase;
}


/*==================================================================
  [ Size ]*/

.size1 {
    width: 355px;
    max-width: 100%;
}

.size2 {
    width: calc(100% - 43px);
}


/*//////////////////////////////////////////////////////////////////
  [ login ]*/

.limiter {
    width: 100%;
    margin: 0 auto;
}

.container-login100 {
    width: 100%;
    min-height: 100vh;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;    
    padding: 0 1em;
}

.wrap-login100 {
    width: 1170px;    
    overflow: hidden;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    /*background: url(http://www.gstatic.com/android/market_images/email/email_mid.png) repeat-y;*/
    
}


/*==================================================================
  [ login more ]*/

.login100-more {
    width: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    z-index: 1;
}

.bg-pic-1 {
    background-image: url('/images/pictures/1.jpg')
}

.bg-pic-2 {
    background-image: url('/images/pictures/2.jpg')
}

.bg-pic-3 {
    background-image: url('/images/pictures/3.jpg')
}

.bg-pic-4 {
    background-image: url('/images/pictures/4.jpg')
}

.bg-pic-5 {
    background-image: url('/images/pictures/5.jpg')
}

.bg-pic-6 {
    background-image: url('/images/pictures/6.jpg')
}

.bg-pic-7 {
    background-image: url('/images/pictures/7.jpg')
}

.bg-pic-8 {
    background-image: url('/images/pictures/8.jpg')
}

.bg-pic-9 {
    background-image: url('/images/pictures/9.jpg')
}

.bg-pic-10, .bg-pic-1_1 {
    background-image: url('/images/pictures/10.jpg')
}

.bg-pic-11, .bg-pic-1_2 {
    background-image: url('/images/pictures/11.jpg')
}

.bg-pic-12 {
    background-image: url('/images/pictures/12.jpg')
}

.bg-pic-13 {
    background-image: url('/images/pictures/13.jpg')
}

.login100-more::before {
    content: "";
    display: block;
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.3);
}


/*==================================================================
  [ Form ]*/

.login100-form {
    width: 50%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    padding: 5em 4em;
}

.login100-form-title {
    font-size: 20px;
    color: #555555;
    line-height: 1.2;
    text-transform: uppercase;
    letter-spacing: 2px;
    text-align: center;
    width: 100%;
    display: block;
}


/*------------------------------------------------------------------
  [ Input ]*/

.rs1-wrap-input100,
.rs2-wrap-input100 {
    width: 50% !important;
}

.rs2-wrap-input100 {
    border-left: none;
}

.input100 {
    display: block;
    width: 100%;
    background: transparent;
    font-size: 18px;
    color: #666666;
    line-height: 1.2;
    padding: 0 25px;
}


/*---------------------------------------------*/

.focus-input100 {
    position: absolute;
    display: block;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    top: -1px;
    left: -1px;
    pointer-events: none;
    border: 1px solid #00ad5f;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;
    -webkit-transform: scaleX(1.1) scaleY(1.3);
    -moz-transform: scaleX(1.1) scaleY(1.3);
    -ms-transform: scaleX(1.1) scaleY(1.3);
    -o-transform: scaleX(1.1) scaleY(1.3);
    transform: scaleX(1.1) scaleY(1.3);
}

.input100:focus+.focus-input100 {
    visibility: visible;
    opacity: 1;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
}


/*------------------------------------------------------------------
  [ Button ]*/

.container-login100-form-btn {
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.login100-form-btn {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    width: 100%;
    height: 50px;
    background: #2cad3c;
    font-size: 12px;
    color: #fff;
    line-height: 1.2;
    text-transform: uppercase;
    letter-spacing: 1px;
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;
}

.login100-form-btn:hover {
    background: #3d3d3d;
}


/*------------------------------------------------------------------
  [ Responsive ]*/

@media (max-width: 992px) {
    .login100-form {
        width: 60%;
        padding-left: 30px;
        padding-right: 30px;
    }
    .login100-more {
        width: 40%;
    }
}

@media (max-width: 768px) {
    .login100-form {
        width: 100%;
    }
    .login100-more {
        width: 100%;
    }
}

@media (max-width: 576px) {
    .login100-form {
        padding-left: 15px;
        padding-right: 15px;
        padding-top: 150px;
    }
    .rs1-wrap-input100,
    .rs2-wrap-input100 {
        width: 100%;
    }
    .rs2-wrap-input100 {
        border-left: 1px solid #e6e6e6;
    }
}


/*------------------------------------------------------------------
  [ Alert validate ]*/

.validate-input {
    position: relative;
}

.alert-validate::before {
    content: attr(data-validate);
    position: absolute;
    max-width: 70%;
    background-color: #fff;
    border: 1px solid #c80000;
    padding: 4px 25px 4px 10px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 12px;
    pointer-events: none;
    color: #c80000;
    font-size: 13px;
    line-height: 1.4;
    text-align: left;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: opacity 0.4s;
    -o-transition: opacity 0.4s;
    -moz-transition: opacity 0.4s;
    transition: opacity 0.4s;
}

.alert-validate::after {
    content: "\f12a";
    font-family: FontAwesome;
    display: block;
    position: absolute;
    color: #c80000;
    font-size: 16px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 18px;
}

.alert-validate:hover:before {
    visibility: visible;
    opacity: 1;
}

@media (max-width: 992px) {
    .alert-validate::before {
        visibility: visible;
        opacity: 1;
    }
}

li.selectable-user {
    transition: all 0.4s;
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
}

li.selectable-user:hover {
    visibility: visible;
    opacity: 1;
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1);
}
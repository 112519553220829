#print-roster .tree-section {
    position: absolute;
    top: 4em;
    bottom: 0;
    left: 0;
    width: 300px;
}

#print-roster .sheet-section {
    position: absolute;
    top: 4em;
    bottom: 0;
    right: 0;
    left: 300px
}

.player-card {
    width: 336;
    height: 192px;
    border-left: 1px dashed #aaa;
    border-right: 1px dashed #aaa;
    border-bottom: 1px dashed #aaa;
    float: left;  
    margin-right: 10px;
}
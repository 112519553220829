.loader {
    position: relative;
    width: 42px;
    height: 10px;
    margin: 12px auto;
}

.dot {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background: #e5e5e5;
    position: absolute;
}

.dot_1 {
    animation: animateDot1 1.5s linear infinite;
    left: 12px;
    background: #28a745;
}

.dot_2 {
    animation: animateDot2 1.5s linear infinite;
    animation-delay: .5s;
    left: 12px * 2;
    background: #008db5;
}

.dot_3 {
    animation: animateDot3 1.5s linear infinite;
    left: 12px;
}

.dot_4 {
    animation: animateDot4 1.5s linear infinite;
    animation-delay: .5s;
    left: 12px * 2;
}

@keyframes animateDot1 {
    0% {
        transform: rotate(0deg) translateX(-12px);
    }
    25% {
        transform: rotate(180deg) translateX(-12px);
    }
    75% {
        transform: rotate(180deg) translateX(-12px);
    }
    100% {
        transform: rotate(360deg) translateX(-12px);
    }
}

@keyframes animateDot2 {
    0% {
        transform: rotate(-0deg) translateX(-12px);
    }
    25% {
        transform: rotate(-180deg) translateX(-12px);
    }
    75% {
        transform: rotate(-180deg) translateX(-12px);
    }
    100% {
        transform: rotate(-360deg) translateX(-12px);
    }
}

@keyframes animateDot3 {
    0% {
        transform: rotate(0deg) translateX(12px);
    }
    25% {
        transform: rotate(180deg) translateX(12px);
    }
    75% {
        transform: rotate(180deg) translateX(12px);
    }
    100% {
        transform: rotate(360deg) translateX(12px);
    }
}

@keyframes animateDot4 {
    0% {
        transform: rotate(-0deg) translateX(12px);
    }
    25% {
        transform: rotate(-180deg) translateX(12px);
    }
    75% {
        transform: rotate(-180deg) translateX(12px);
    }
    100% {
        transform: rotate(-360deg) translateX(12px);
    }
}


/* Line Loader */

.cssload-container {
    background: rgb(255, 255, 255);
}

.cssload-container div {
    width: 8px;
    height: 8px;
    position: absolute;
    background-color: rgb(204, 204, 204);
    top: 0;
    border-radius: 50%;
}

.cssload-container div:nth-child(1) {
    background-color: #008db5;
    animation: cssload-move 2.3s infinite cubic-bezier(0.2, 0.64, 0.81, 0.23);
    -o-animation: cssload-move 2.3s infinite cubic-bezier(0.2, 0.64, 0.81, 0.23);
    -ms-animation: cssload-move 2.3s infinite cubic-bezier(0.2, 0.64, 0.81, 0.23);
    -webkit-animation: cssload-move 2.3s infinite cubic-bezier(0.2, 0.64, 0.81, 0.23);
    -moz-animation: cssload-move 2.3s infinite cubic-bezier(0.2, 0.64, 0.81, 0.23);
}

.cssload-container div:nth-child(2) {
    background-color: #28a745;
    animation: cssload-move 2.3s 172.5ms infinite cubic-bezier(0.2, 0.64, 0.81, 0.23);
    -o-animation: cssload-move 2.3s 172.5ms infinite cubic-bezier(0.2, 0.64, 0.81, 0.23);
    -ms-animation: cssload-move 2.3s 172.5ms infinite cubic-bezier(0.2, 0.64, 0.81, 0.23);
    -webkit-animation: cssload-move 2.3s 172.5ms infinite cubic-bezier(0.2, 0.64, 0.81, 0.23);
    -moz-animation: cssload-move 2.3s 172.5ms infinite cubic-bezier(0.2, 0.64, 0.81, 0.23);
}

.cssload-container div:nth-child(3) {
    background-color: #e5e5e5;
    animation: cssload-move 2.3s 345ms infinite cubic-bezier(0.2, 0.64, 0.81, 0.23);
    -o-animation: cssload-move 2.3s 345ms infinite cubic-bezier(0.2, 0.64, 0.81, 0.23);
    -ms-animation: cssload-move 2.3s 345ms infinite cubic-bezier(0.2, 0.64, 0.81, 0.23);
    -webkit-animation: cssload-move 2.3s 345ms infinite cubic-bezier(0.2, 0.64, 0.81, 0.23);
    -moz-animation: cssload-move 2.3s 345ms infinite cubic-bezier(0.2, 0.64, 0.81, 0.23);
}

.cssload-container div:nth-child(4) {
    background-color: #e5e5e5;
    animation: cssload-move 2.3s 517.5ms infinite cubic-bezier(0.2, 0.64, 0.81, 0.23);
    -o-animation: cssload-move 2.3s 517.5ms infinite cubic-bezier(0.2, 0.64, 0.81, 0.23);
    -ms-animation: cssload-move 2.3s 517.5ms infinite cubic-bezier(0.2, 0.64, 0.81, 0.23);
    -webkit-animation: cssload-move 2.3s 517.5ms infinite cubic-bezier(0.2, 0.64, 0.81, 0.23);
    -moz-animation: cssload-move 2.3s 517.5ms infinite cubic-bezier(0.2, 0.64, 0.81, 0.23);
}

@keyframes cssload-move {
    0% {
        left: 0%;
    }
    100% {
        left: 100%;
    }
}

@-o-keyframes cssload-move {
    0% {
        left: 0%;
    }
    100% {
        left: 100%;
    }
}

@-ms-keyframes cssload-move {
    0% {
        left: 0%;
    }
    100% {
        left: 100%;
    }
}

@-webkit-keyframes cssload-move {
    0% {
        left: 0%;
    }
    100% {
        left: 100%;
    }
}

@-moz-keyframes cssload-move {
    0% {
        left: 0%;
    }
    100% {
        left: 100%;
    }
}
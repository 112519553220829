#left-menu {
    position: absolute;
    top: 0em;
    left: 0;
    bottom: 0;
    width: 12em;
    padding: 0 5px;
    overflow-y: auto;
}

#left-menu a,
#left-menu button {
    padding-top: 1.5em;
    padding-bottom: 1.5em;
    margin-bottom: 5px !important;
    margin-top: 0;
    padding-left: 0;
    padding-right: 0;
}

#left-menu a:hover, .menu-wrapper > button:hover {
    color: white !important;
}

#left-menu .drop {
    height: 3em;
    padding: 0
}

#left-menu .name {
    width: 12em;
    display: inline-block;
    word-wrap: break-word;
    white-space: normal !important;
    text-align: left;
}

#left-menu .icon {
    width: 1em;
    margin-left: 0.25em;
    margin-right: 0.50em;
    font-size: 1.5em !important;
}

#left-menu .fa-caret-down,
#left-menu .fa-caret-up {
    padding-right: 0.3em;
}

#left-menu .menu-wrapper {
    margin: 0;
    padding: 0;
}

#left-menu .sub-menu-wrapper {
    background-color: #e5e5e5;
    width: 100%;
    overflow-y: hidden;
    /*max-height: 1000px;*/
    transition: max-height 0.25s ease-in;
}

#left-menu .menu-wrapper.off>.sub-menu-wrapper {
    max-height: 0;
    transition: max-height 0.15s ease-out;
    margin-bottom: 0;
}

#left-menu .menu-wrapper.on>.sub-menu-wrapper {
    margin-bottom: 5px;
}

#left-menu .sub-menu-wrapper .btn {
    height: 3em;
    text-align: left;
}

#left-menu .sub-menu-wrapper .btn.btn-selected {
    border-top: 1px solid gray;
    border-bottom: 1px solid gray;
}
.bigest-font {
    font-size: 3.5em !important;
}

.big-font {
    font-size: 2em !important;
}

.font-120 {
    font-size: 120pt !important;
}

.font-100 {
    font-size: 100pt !important;
}

.font-80 {
    font-size: 80pt !important;
}

.font-72 {
    font-size: 72pt !important;
}

.font-64 {
    font-size: 64pt !important;
}

.font-60 {
    font-size: 60pt !important;
}

.font-56 {
    font-size: 56pt !important;
}

.font-50 {
    font-size: 50pt !important;
}

.font-40 {
    font-size: 40pt !important;
}

.font-36 {
    font-size: 36pt !important;
}

.font-32 {
    font-size: 32pt !important;
}

.font-30 {
    font-size: 30pt !important;
}

.font-28 {
    font-size: 28pt !important;
}


.font-24 {
    font-size: 24pt !important;
}

.font-20 {
    font-size: 20pt !important;
}

.no-border {
    border-width: 0 !important;
}

.font-18 {
    font-size: 18pt !important;
}

.font-16 {
    font-size: 16pt !important;
}

.font-14 {
    font-size: 14pt !important;
}

.font-12 {
    font-size: 12pt !important;
}

.font-11 {
    font-size: 11pt !important;
}

.font-10 {
    font-size: 10pt !important;
}

.font-9 {
    font-size: 9pt !important;
}

.font-8 {
    font-size: 8pt !important;
}

.font-7 {
    font-size: 7pt !important;
}

.font-6 {
    font-size: 6pt !important;
}

.font-0 {
    font-size: 6pt;
}

.font-1 {
    font-size: 8pt;
}

.font-2 {
    font-size: 8pt;
}

.font-3 {
    font-size: 12pt;
}

.font-4 {
    font-size: 14pt;
}

.toUpper {
    text-transform: uppercase;
}

.toBold {
    font-weight: 700;
}

.toNormal {
    font-weight: normal !important;
}

.dodge {
    mix-blend-mode: exclusion;
}

.burn {
    mix-blend-mode: color-burn;
}

.shadow-text {
    text-shadow: 1px 1px 4px #000000;
}

.bungee {
    font-family: 'Bungee', cursive;
}

.kanit {
    font-family: 'Kanit', sans-serif;
}

.kalam {
    font-family: 'Kalam', cursive;
}

.hind {
    font-family: 'Hind Vadodara', 'Hind';
}

.titan {
    font-family: 'Titan One', sans-serif;
}

.sans {
    font-family: 'Open Sans', sans-serif;
}
.sans.toBold {
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
}
.montserrat {
    font-family: 'Montserrat', sans-serif;
}
.montserrat.toBold {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
}
.dosis {
    font-family: 'Dosis', sans-serif;
}

.sil {
    font-family: 'Shadows Into Light', cursive;
}

.anton {
    font-family: 'Anton', sans-serif;
}

.watermark {
    font-size: 8pt;
    color: #ccc;
    text-align: center;
}

.vertical-text {
    transform: rotate(90deg);
    transform-origin: left top 0;
    float: left;
}

.trimed-text {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.marker-small {
    font-size: 40px;
    margin-left: -12px;
    margin-top: -12px;
}

.marker-medium {
    font-size: 76px;
    margin-left: -23px;
    margin-top: -23px;
}

.marker-large {
    font-size: 120px;
    margin-left: -45px;
    margin-top: -45px;
}

.line1{
    line-height: 1;
}
#points {
    border-bottom: 1px solid #84878C;
}

#points .title {
    font-size: 14pt;
    padding: 0.5em 0;
    background-color: black;
}

#points .filters-wrapper {
    margin-left: 2em;
}

#points .tie-breakers-wrapper {
    border-left: 1px solid #84878c;
}

#points .tie {
    border-bottom: 1px solid #84878C;
    padding-bottom: 0.5em;
}

#points .tie:last-child {
    border-bottom: 0 !important;
}